import React, { lazy, Suspense, SuspenseProps } from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'
const TournamentsCalendar = lazy(() =>
  import('src/components/tournaments-calendar/tournaments-calendar')
)
// This is temporary, don't import styles from other components
import * as styles from 'src/components/tournaments-calendar/tournaments-calendar.module.less'

const isBrowser = typeof window !== 'undefined'

const CalendarPage = () => {
  usePrivateRoute()

  return (
    <Layout>
      <SEO title="Tournaments Calendar" />
      {isBrowser && (
        <div className={styles.container}>
          <Suspense fallback={<div />}>
            <TournamentsCalendar />
          </Suspense>
        </div>
      )}
    </Layout>
  )
}

export default CalendarPage
